export namespace Ajax {
  const serverBase = '/api/';
  const timeout = 15 * 1000;

  export function post<T, U>(path: string, data: any, headers: Header[] = []): Callbacks<T, U> {
    return execute('POST', path, data, headers);
  }

  export function put<T, U>(path: string, data: any, headers: Header[] = []): Callbacks<T, U> {
    return execute('PUT', path, data, headers);
  }

  export function patch<T, U>(path: string, data: any, headers: Header[] = []): Callbacks<T, U> {
    return execute('PATCH', path, data, headers);
  }

  export function get<T, U>(path: string, headers: Header[] = []): Callbacks<T, U> {
    return execute('GET', path, undefined, headers);
  }

  export function del<T, U>(path: string, data?: any, headers: Header[] = []): Callbacks<T, U> {
    return execute('DELETE', path, data, headers);
  }

  export interface SuccessListener<T> { (res: T): void; }
  export interface FailureListener<T> { (status: number, res: T): void; }
  export interface ResponseMapper<T> { (res: any): T; }

  export type Header = {
    name: string;
    value: string;
  }

  export type FieldError = {
    field: string;
    message: string;
  }

  export class Callbacks<T, U> {
    private successListeners: SuccessListener<T>[] = [];
    private defaultFailureListener: FailureListener<U>;
    private failureListeners: FailureListener<U>[] = [];
    private statusFailureListeneres: {[status: number]: FailureListener<U>[]} = {};
    private responseMappers: ResponseMapper<T>[] = [];
    private errorMappers: ResponseMapper<U>[] = [];
    private completeListeners: Function[] = [];
    private wait: number = 0;
    private minExec: number;
    private startMs = new Date().getTime();

    public waitMs(val: number): this {
      this.wait = val;
      return this;
    }

    public minExecMs(val: number): this {
      this.minExec = val;
      return this;
    }

    public map<R>(mapper: ResponseMapper<T>): Callbacks<R, U> {
      this.responseMappers.push(mapper);
      return this as any as Callbacks<R, U>;
    }

    public mapError(mapper: ResponseMapper<U>): this {
      this.errorMappers.push(mapper);
      return this;
    }

    public onSuccess(listener: SuccessListener<T>): this {
      this.successListeners.push(listener);
      return this;
    }

    public setDefaultFailureListener(listener: FailureListener<U>): this {
      this.defaultFailureListener = listener;
      return this;
    }

    public onFailure(listener: FailureListener<U>): this;
    public onFailure(status: number, listener: FailureListener<U>): this;
    public onFailure(statusOrListener: number | FailureListener<U>, listener?: FailureListener<U>): this {
      if (arguments.length === 2) {
        const listeners: FailureListener<U>[] = this.statusFailureListeneres[statusOrListener as number] || [];
        listeners.push(listener as FailureListener<U>);
        this.statusFailureListeneres[statusOrListener as number] = listeners;
      } else {
        this.failureListeners.push(statusOrListener as FailureListener<U>);
      }
      return this;
    }

    public onComplete(listener: Function): this {
      this.completeListeners.push(listener);
      return this;
    }

    finish(isSuccess: boolean, status: number, result: T | U): void {
      const mappers = isSuccess ? this.responseMappers : this.errorMappers;
      for (const mapper of mappers) {
        result = mapper(result);
      }
      const elapsedMs = new Date().getTime() - this.startMs;
      const remainingMs = this.minExec ? this.minExec - elapsedMs : 0;
      const waitMs = remainingMs > this.wait ? remainingMs : this.wait;
      setTimeout(() => {
        const failureListeners = this.resolveFailureListeners(status);
        if (isSuccess) {
          this.successListeners.forEach(l => { l(result as T); });
        } else if (failureListeners.length) {
          failureListeners.forEach(l => { l(status, result as U); });
        } else if (status === 401) {
          window.location.href = window.location.origin;
        } else {
          console.error(result);
        }
        this.completeListeners.forEach(l => { l(); });
      }, waitMs);
    }

    private resolveFailureListeners(status: number): FailureListener<U>[] {
      let failureListeners: FailureListener<U>[] = this.failureListeners;
      if (this.statusFailureListeneres[status]) {
        failureListeners.push(...this.statusFailureListeneres[status]);
      }
      if (!failureListeners.length && this.defaultFailureListener) {
        failureListeners.push(this.defaultFailureListener);
      }
      return failureListeners;
    }
  }

  function execute<T, U>(method: string, path: string, data: any, headers: Header[]): Callbacks<T, U> {
    if (data && !headers.find(h => h.name === 'Content-Type')) {
      headers.push({name: 'Content-Type', value: 'application/json'});
    }
    const callbacks: Callbacks<T, U> = new Callbacks();
    const http = new XMLHttpRequest();
    http.timeout = timeout;
    http.onload = () => {
      if (200 <= http.status && http.status < 300) {
        callbacks.finish(true, http.status, processResponse(http));
      } else {
        callbacks.finish(false, http.status, processResponse(http));
      }
    };
    http.onerror = () => {
      callbacks.finish(false, http.status, processResponse(http));
    };
    http.ontimeout = () => {
      callbacks.finish(false, http.status, 'Timeout' as any);
    };
    http.open(method, serverBase + path);
    for (const header of headers) {
      http.setRequestHeader(header.name, header.value);
    }
    http.send(processRequest(data));
    return callbacks;
  }

  function processRequest(data: any): string | undefined {
    if (typeof data === 'undefined') {
      return undefined;
    }
    return typeof data === 'string' ? data : JSON.stringify(data);
  }

  function processResponse<T>(http: XMLHttpRequest): T {
    return http.getResponseHeader('Content-Type') === 'application/json' ? JSON.parse(http.responseText) : http.responseText;
  }
}