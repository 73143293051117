import { Ajax } from './ajax';
import { DOM } from './dom';

export namespace Components {
  export function input(name: string, label: string, placeholder: string, value: string = '', type: 'email' | 'tel' | 'text' | 'number' = 'text'): string {
    return `
      <div class="input-container ${name}-container">
        <label for="${name}">${label}</label>
        <input id="${name}" name="${name}" type="${type}" placeholder="${placeholder}" value="${value || ''}" />
        <label id="${name}-error" class="error"></label>
      </div>`;
  }

  export function select(name: string, label: string, placeholder: string, multiple: boolean = false): string {
    return `
      <div class="input-container">
        <label for="${name}">${label}</label>
        <select ${multiple ? 'multiple' : ''} id="${name}" name="${name}" placeholder="${placeholder}"></select>
        <label id="${name}-error" class="error"></label>
      </div>`;
  }

  type SelectAndInputProps = {
    inputName: string,
    inputLabel: string,
    inputType: string,
    inputPlaceholder: string,
    inputVal?: string,
    selectName: string,
    selectPlaceholder: string,
    multiple?: boolean
  }

  export function selectAndInput({
                                   inputName,
                                   inputLabel,
                                   inputType,
                                   inputPlaceholder,
                                   inputVal = '',
                                   selectName,
                                   selectPlaceholder,
                                   multiple = false
                                 }: SelectAndInputProps): string {
    return `
    <div class="input-container ${inputName}-container">
        <label for="${inputName}">${inputLabel}</label>
        <div class="select-input-wrapper">
            <select ${multiple ? 'multiple' : ''} id="${selectName}" name="${selectName}" placeholder="${selectPlaceholder}"></select>
            <input id="${inputName}" name="${inputName}" type="${inputType}" placeholder="${inputPlaceholder}" value="${inputVal}" />
        </div>
        <label id="${inputName}-error" class="error"></label>
         <label id="${selectName}-error" class="error"></label>
      </div>
    `;
  }

  export type TextAreaProps = { name: string, label: string, placeholder: string, value?: string, maxChars?: number }

  export function textarea({ name, label, placeholder, value = '', maxChars }: TextAreaProps): string {
    return `
      <div class="input-container ${name}-container">
        <label for="${name}">${label}</label>
        <textarea id="${name}" name="${name}" placeholder="${placeholder}">${value || ''}</textarea>
        <label id="${name}-char-counter" class="max-char-counter">${maxChars !== undefined ? '0/' + maxChars : ''}</label>
        <label id="${name}-error" class="error"></label>
      </div>`;
  }

  export function checkbox(name: string, label: string, checked: boolean = false): string {
    return `
      <div class="${name}-container">
        <input id="${name}" name="${name}" type="checkbox" ${checked ? 'checked' : ''}/>
        <label for="${name}">${label}</label>
        <label id="${name}-error" class="error"></label>
      </div>`;
  }

  export type Radio = { name: string, label: string, value: string }

  export function radio(groupName: string, options: Radio[], selectedValue?: string): string {
    const member = (o: Radio): string => {
      return `
        <div>
          <input id="${o.name}" type="radio" name="${groupName}" value="${o.value}" ${o.value === selectedValue ? 'checked' : ''} />
          <label for="${o.name}">${o.label}</label>
        </div>`;
    };
    return options.map(o => member(o)).join('');
  }

  export function button(id: string, label: string, type: 'freelancer' | 'company' | '' = ''): string {
    return `<button id="${id}" class="gigs-btn ${type}" type="button">${label}</label>`;
  }

  export function backFormButton(id: string, label: string): string {
    return `<button id="${id}" class="gigs-btn grayed centered" type="button"><div class="gr-icon gri-left-arrow"></div>${label}</label>`;
  }

  export function nextFormButton(id: string, label: string, type: 'freelancer' | 'company'): string {
    return `<button id="${id}" class="gigs-btn ${type} centered" type="button">${label}<div class="gr-icon gri-right-arrow"></div></label>`;
  }

  export function execute<T, R>(btn: HTMLButtonElement, ajax: Ajax.Callbacks<T, R>): Ajax.Callbacks<T, R> {
    btn.classList.add('x-loading');
    btn.disabled = true;
    return ajax.minExecMs(500)
      .onComplete(() => {
        btn.disabled = false;
        btn.classList.remove('x-loading');
      });
  }

  export function showOverlay(content: string): void {
    const elem = document.createElement('div');
    elem.innerHTML = `
      <div id="overlay">
        <img class="logo" src="./logo.svg" />
        <div class="overlay-content">
          <div id="close" class="gr-icon gri-close"></div>
          <div class="content-container">
            ${content}
          </div>
        </div>
      </div>`;
    document.body.appendChild(elem.children[0]);
    document.body.classList.add('show-captcha', 'disable-scroll');
    DOM.onIdClick('close', closeOverlayWithBack);
  }

  export function closeOverlay(): void {
    document.body.classList.remove('show-captcha', 'disable-scroll');
    const overlay = DOM.byId('overlay');
    if (overlay) {
      overlay.remove();
    }
  }

  export function closeOverlayWithBack(): void {
    document.body.classList.remove('show-captcha', 'disable-scroll');
    const overlay = DOM.byId('overlay');
    if (overlay) {
      overlay.remove();
    }
    history.back();
  }

  export function steps(count: number, current: number, type: 'freelancer' | 'company'): string {
    const createStep = (idx: number) => `<span class="gigs-step${idx === current ? ' active' : ''}">${idx}</span>`;
    const createDesktopContent = () => {
      let content = '';
      for (let i = 1; i < count; ++i) {
        content += createStep(i) + '<span class="spacer"></span>';
      }
      content += createStep(count);
      return content;
    };
    const createMobileContent = () => {
      let content = '';
      if (current !== 1) {
        content += createStep(1) + '<span class="spacer"></span>';
      }
      if (current !== count) {
        content += createStep(current) + '<span class="spacer"></span>' + createStep(count);
      } else {
        content += createStep(current);
      }
      return content;
    };
    if (count > 3) {
      return `<div class="gigs-steps ${type} desktop">${createDesktopContent()}</div>
              <div class="gigs-steps ${type} mobile">${createMobileContent()}</div>`;
    }
    return `<div class="gigs-steps ${type}">${createDesktopContent()}</div>`;
  }

  export function hint(id: string, content: string): string {
    return `
      <div id="${id}" class="gigs-hint">
        <img src="./circle-question.svg" class="hint-icon" />
        <div class="hint-content">${content}</div>
      </div>`;
  }

  export function updateRequired(fieldName: string, isRequired: boolean): void {
    DOM.bySelector(`label[for=${fieldName}]`, elem => {
      const currentRequired = elem.querySelector('.required');
      if (currentRequired && !isRequired) {
        currentRequired.remove();
      }
      if (!currentRequired && isRequired) {
        elem.innerHTML += '<span class="required">*</span>';
      }
    });
  }
}