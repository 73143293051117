declare const grecaptcha: any;

export namespace ReCaptcha {
  export function getToken(action: string, callback: (token: string) => void): void {
    const key = window.location.hostname === 'gigsremote.com' ? '6Lf2_bQhAAAAAEi_hO5BYUB7SrBSRnLNU9phG3ql' : '6LekrKIhAAAAANH8wv_aILzyt2mzMH7s4fJJovFR';
    grecaptcha.enterprise.ready((): void => {
      grecaptcha.enterprise.execute(key, {action: action}).then((token: string): void => {
        callback(token);
      });
    });
  }
}