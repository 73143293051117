import { DOM } from '../core/dom';
import { Slider } from '../core/slider';
import { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } from '../variables';

const BREAKPOINTS = {};
BREAKPOINTS[BREAKPOINT_DESKTOP] = { perPage: 2 };
BREAKPOINTS[BREAKPOINT_TABLET] = { perPage: 1 };
const SLIDER_SETTINGS = { type: 'loop', perPage: 3, breakpoints: BREAKPOINTS };


type Testemonial = {
  name: string;
  position: string;
  type: 'company' | 'freelancer';
  content: string;
}

const company: Testemonial[] = [
  {
    name: 'VP Product Engineering',
    position: 'Global eCommerce Company',
    type: 'company',
    content: 'The on-demand aspect was key for delivering our project. Quick onboarding, delivery, and offboarding. We got senior freelancers with eCommerce expertise at a very reasonable price.'
  },
  {
    name: 'CMO',
    position: 'Global IT Service Company',
    type: 'company',
    content: 'I am impressed by the speed of sourcing, the quality-to-price ratio of the freelancers, and the flexibility of GigsRemote. This model is the future of work. We have our core team and used GigsRemote to help in peaks with additional capacity, experienced, with the right tech skills.'
  },
  {
    name: 'Director of Talent Acquisition',
    position: 'Global IT Service Company',
    type: 'company',
    content: 'GigsRemote helped us to quickly scale one of our key Java teams. The sourcing process took 2 weeks from contact to the start of the freelancer!'
  },
  {
    name: 'CEO',
    position: 'IT Service Company',
    type: 'company',
    content: 'Our clients and budgets are tough to plan against, with the flexible cost model by GigsRemote we have a good option to plug-in senior people into ongoing projects as additional budget is approved.'
  },
  {
    name: 'Security Director',
    position: 'US Manufacturing Company',
    type: 'company',
    content: 'We needed a security and DevOps audit. GigsRemote matched us very quickly with the right freelancers. They even delivered in our time zone.'
  }
];

const freelancer: Testemonial = {
  name: 'Jane Doe',
  position: 'VP Product Engineering, Global eCommerce Company',
  type: 'freelancer',
  content: 'Thanks to Gigs Remote I am working for the best company in the world'
};

export namespace Testemonials {
  export function onload(): void {
    DOM.byId('testemonials').innerHTML = `
      <h1 class="no-margin medium">What our clients say about us</h1>
      ${Slider.create('company-testemonials', company.map(t => render(t)))}`;
    // Example of removed freelancer testimonals
    //${Slider.create('freelancer-testemonials', Array(12).fill(freelancer, 0, 12).map(t => render(t)))}
    Slider.setup('company-testemonials', 'plain', SLIDER_SETTINGS);
    // Slider.setup('freelancer-testemonials', 'plain', SLIDER_SETTINGS);
  }

  function render(t: Testemonial): string {
    return `
      <div class="testemonial ${t.type}">
        <span class="type">${t.type === 'company' ? 'Business' : 'Freelancer'}</span>
        <span class="name-position">
          <h5 class="name no-margin">${t.name}</h5>
          <p class="position small">${t.position}</p>
        </span>
        <span class="highlight"></span>
        <p class="small">${t.content}</p>
      </div>`;
    // Example of image
    //        <img alt="${t.name}'s picture" src="${t.type === 'company' ? './tmp_person_1.jpg' : './tmp_person_2.jpg'}" />
  }
}