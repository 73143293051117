import './freelancer-signup.sass';

import { Ajax } from './ajax';
import { BackendError, City, CommonRepo, Country, ValidationRule } from './common-repository';
import { Components } from './components';
import { DOM } from './dom';
import { Forms } from './forms';
import { ReCaptcha } from './recaptcha';
import { Select } from './select';
import { Validation } from './validation';
import { Auth } from './social-login';
import { Texts } from './texts';
import { CountryCode, getCountries, getCountryCallingCode, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { countryListAlpha2 } from './countriesList';

namespace Repo {
  export function register(freelancer: Freelancer, token: string): Ajax.Callbacks<void, BackendError> {
    return Ajax.post('v1/freelancers/register', freelancer, [{ name: 'X-Token', value: token }]);
  }

  export function verifyFile(name: string, content: string): Ajax.Callbacks<void, BackendError> {
    return Ajax.post('v1/validations/file', { fileName: name, fileContent: content });
  }
}

export namespace FreelancerSignup {
  const DEFAULT_COUNTRY = 'BGR';
  const DEFAULT_TOWN = 'Sofia';
  
  let formData: Freelancer;
  let lastFile: FileUpload;
  let countriesCache: Country[];
  let firstStepRules: ValidationRule[] = [];
  let secondStepRules: ValidationRule[] = [];
  let thirdStepRules: ValidationRule[] = [];
  let lastLoadedTowns: City[] = [];

  export function onload(): void {
    /*SocialLogin.checkForAuthCode(auth => {
      displayWithAuth(auth);
    });*/
  }

  export function display(): void {
    history.pushState({ modal: 'freelancer-signup' }, "", "#signup");
    window.onpopstate = (() => {
      Components.closeOverlay();
    });
    displayWithAuth();
  }

  function displayWithAuth(auth: Auth = {} as Auth): void {
    formData = auth as any as Freelancer;
    lastFile = new FileUpload();
    Components.showOverlay('<form id="freelancer-signup"></form>');
    displayFirstStep();
    loadRules();
  }

  const setSelectFieldCodePlaceholder = (code: CountryCode) => {
    DOM.byClass('choices__item--selectable')[0].innerHTML = `(+${getCountryCallingCode(code)})`;
  };

  function displayFirstStep(): void {
    const initialPhoneValue = formData.phone ? parsePhoneNumber(formData.phone) : { nationalNumber: '', country: 'BG' } as PhoneNumber;
    formData.phone = initialPhoneValue.nationalNumber;
    Forms.replaceContent('freelancer-signup', `
      ${Components.steps(3, 1, 'freelancer')}
      <h4 class="no-margin">${Texts.FREELANCER_SIGNUP_WELCOME_TITLE}</h4>
      <p class="normal">${Texts.FREELANCER_SIGNUP_WELCOME_SUB_TITLE}</p>
      ${Components.input('firstName', Texts.FIRST_NAME, Texts.FIRST_NAME, formData.firstName)}
      ${Components.input('lastName', Texts.LAST_NAME, Texts.LAST_NAME, formData.lastName)}
      ${Components.input('email', Texts.EMAIL, Texts.EMAIL, formData.email, 'email')}
      ${Components.selectAndInput({
      inputName: 'phone',
      inputLabel: Texts.PHONE,
      inputPlaceholder: Texts.PHONE,
      inputVal: formData.phone,
      inputType: 'tel',
      selectName: 'country-code',
      selectPlaceholder: Texts.COUNTRY_CODE
      })}
      <div class="social">
        <button id="freelancer-signup-linkedin" type="button"><div class="gr-icon gri-linkedin2"></div><p class="small">${Texts.SIGN_UP_LINKEDIN}</p></button>
        <div id="freelancer-signup-google"></div>
        <button id="freelancer-signup-apple" type="button"><div class="gr-icon gri-apple"></div><p class="small">${Texts.SIGN_UP_APPLE}</p></button>
      </div>
      ${Components.checkbox('termsAccepted', Texts.COMPANY_FREELANCER_SIGNUP_TERMS_ACCEPTED, formData.termsAccepted)}
      <label id="freelancer-signup-error" class="error"></label>
      <nav>
        ${Components.nextFormButton('next', Texts.NEXT, 'freelancer')}
      </nav>`);
    Forms.updateRequiredFields('freelancer-signup', Validation.resolveRequiredFields(firstStepRules));
    const allCountries = getCountries();
    const selectEl = Select.setup('country-code', allCountries.map(c =>
      ({ label: (countryListAlpha2[c] || c) + `(+${getCountryCallingCode(c)})`, value: c })), false, Texts.COUNTRY);
    selectEl.setValue(initialPhoneValue.country);
    setSelectFieldCodePlaceholder(initialPhoneValue.country);
    const countrySelect: HTMLSelectElement = DOM.byId('country-code');
    countrySelect.addEventListener('change', (e: Event) => {
      if ((e.target as any).value) {
        setSelectFieldCodePlaceholder((e.target as any).value);
      }
    });

    DOM.onIdClick<HTMLButtonElement>('next', (_, btn) => {
      Forms.read('freelancer-signup', formData);
      formData.termsAccepted = Forms.convertCheckboxVal(formData.termsAccepted);
      validate(firstStepRules, () => {
        if (!formData['country-code']) {
          return validate([{
            fieldName: 'phone',
            type: 'LENGTH',
            max: -1,
            msgOverride: 'Please select country code'
          }], () => {return;});
        }
        if (!isValidPhoneNumber(formData.phone, formData['country-code'] as CountryCode)) {
          return validate([{
            fieldName: 'phone',
            type: 'LENGTH',
            max: -1,
            msgOverride: 'Please enter a valid phone number'
          }], () => {return;});
        }
        const parsedPhoneNumber = parsePhoneNumber(formData.phone, formData['country-code'] as CountryCode).format('E.164');
        const { phone, ['country-code']: _, ...restData } = formData as any;
        formData = { ...restData, phone: parsedPhoneNumber } as Freelancer;
        ReCaptcha.getToken('email_check', token => {
          Components.execute(btn, CommonRepo.checkEmail(formData.email, token))
            .onSuccess(displaySecondStep)
            .onFailure(409, (_, err) => {
              Forms.clearErrors('freelancer-signup');
              DOM.byId('email-error').innerText = Texts.ERROR_FREELANCER_EMAIL_ALREADY_TAKEN;
            })
            .setDefaultFailureListener(() => { Forms.setError('freelancer-signup', Texts.ERROR_GENERAL); });
        });
      });
    });
    /*
    SocialLogin.setup('freelancer-signup-linkedin', 'freelancer-signup-google', 'freelancer-signup-apple', auth => {
      DOM.byId<HTMLInputElement>('firstName').value = auth.firstName;
      DOM.byId<HTMLInputElement>('lastName').value = auth.lastName;
      DOM.byId<HTMLInputElement>('email').value = auth.email;
    });*/
  }

  function displaySecondStep(): void {
    const acquireCountries = (callback: (countries: Country[]) => void) => {
      if (countriesCache) {
        callback(countriesCache);
      } else {
        CommonRepo.getCountries().onSuccess(countries => {
          countriesCache = countries;
          callback(countries);
        });
      }
    };
    const updateTownsList = (country: string, cb?: (cities: City[]) => void) => {
      CommonRepo.getTowns(country).onSuccess(country => {
        lastLoadedTowns = country.cities;
        cb?.(lastLoadedTowns);
      });
    };
    Forms.replaceContent('freelancer-signup', `
      ${Components.steps(3, 2, 'freelancer')}
      <p class="normal-bold">${Texts.YOUR_LOCATION}</p>
      ${Components.select('country', Texts.COUNTRY, Texts.COUNTRY)}
      ${Components.select('town', Texts.CITY, Texts.CITY)}
      <p class="normal-bold">${Texts.SHARE_WITH_US_YOUR_PROFESSIONAL_PROFILE}</p>
      <div class="input-container">
        <label for="cv"><a id="resume-link" class="gigs-lnk gr-icon gri-upload">${Texts.UPLOAD_YOUR_RESUME}</a></label>
        <input id="cv" name="cv" type="file" style="display: none" />
        <label id="cv-error" class="error"></label>
        <label id="resume-current" for="cv">${lastFile.display()}</label>
      </div>
      <p class="normal-bold">${Texts.LINK_WEBSITE_OR_SOCIAL_PROFILE}</p>
      <p class="small">${Texts.LINK_WEBSITE_BENEFITS}</p>
      ${Components.input('linkedIn', Texts.URL, Texts.URL, formData.linkedIn)}
      <nav>
        ${Components.backFormButton('back', Texts.BACK)}
        ${Components.nextFormButton('next', Texts.NEXT, 'freelancer')}
      </nav>`);
    Forms.updateRequiredFields('freelancer-signup', Validation.resolveRequiredFields(secondStepRules));
    const initialTownOptions = formData.town && lastLoadedTowns.findIndex(t => t.name === formData.town) === -1 ? [{ name: formData.town }].concat(lastLoadedTowns) : lastLoadedTowns;
    const townChoices = Select.setup('town', initialTownOptions.map(t => {
      return {
        label: t.name,
        value: t.name
      };
    }), true, Texts.CITY);
    townChoices.setValue(formData.town || DEFAULT_TOWN);
    const countryChoices = Select.setup('country', [], false, Texts.COUNTRY);
    acquireCountries(countries => {
      countryChoices.updateOptions(countries.map(c => { return { label: c.name, value: c.countryISO3code }; }));
      countryChoices.setValue(formData.country || DEFAULT_COUNTRY);
      const countrySelect: HTMLSelectElement = DOM.byId('country');
      countrySelect.addEventListener('change', () => {
        updateTownsList(countrySelect.value, (cities) => {
          townChoices.clearStore();
          townChoices.updateOptions(cities.map(t => { return { label: t.name, value: t.name }; }));
        });
      });
      if(!initialTownOptions.length && DEFAULT_COUNTRY) {
        updateTownsList(countrySelect.value, (cities) => {
          townChoices.updateOptions(cities.map(t => { return { label: t.name, value: t.name }; }));
          townChoices.setValue(formData.town || DEFAULT_TOWN);
        });
      }
    });
    setupFileHandling();

    DOM.onIdClick('back', () => {
      Forms.read('freelancer-signup', formData);
      displayFirstStep();
    });
    DOM.onIdClick('next', () => {
      Forms.read('freelancer-signup', formData);
      if (!formData.resume && !formData.linkedIn.trim()) {
        Forms.handle('freelancer-signup', {
          field: 'linkedIn',
          message: Texts.ERROR_FREELANCER_RESUME_OR_URL_REQUIRED
        });
      } else {
        validate(secondStepRules, displayThirdStep);
      }
    });
  }

  function displayThirdStep(): void {
    Forms.replaceContent('freelancer-signup', `
      ${Components.steps(3, 3, 'freelancer')}
      <p class="normal-bold">${Texts.WHAT_IS_YOUR_MOTIVATION_TO_JOIN}</p>
      ${Components.checkbox('motivFlexability', Texts.MOTIVATION_FLEXABILITY, formData.motivFlexability)}
      ${Components.checkbox('motivExtraMoney', Texts.MOTIVATION_EXTRA_MONEY, formData.motivExtraMoney)}
      ${Components.checkbox('motivLocation', Texts.MOTIVATION_LOCATION, formData.motivLocation)}
      ${Components.checkbox('motivProjects', Texts.MOTIVATION_PROJECTS, formData.motivProjects)}
      ${Components.checkbox('motivOfficeDynamics', Texts.MOTIVATION_OFFICE_DYNAMICS, formData.motivOfficeDynamics)}
      ${Components.checkbox('motivPursuePassions', Texts.MOTIVATION_PURSUE_PASSIONS, formData.motivPursuePassions)}
      ${Components.checkbox('motivOwnBoss', Texts.MOTIVATION_OWN_BOSS, formData.motivOwnBoss)}
      <p class="normal-bold">${Texts.HOW_DID_YOU_HEAR_ABOUT_US}</p>
      ${Components.checkbox('refFriend', Texts.FROM_A_FRIED, formData.refFriend)}
      ${Components.checkbox('refLinkedIn', Texts.FROM_LINKEDIN, formData.refLinkedIn)}
      ${Components.checkbox('refFacebook', Texts.FROM_FACEBOOK, formData.refFacebook)}
      ${Components.checkbox('refInternet', Texts.FROM_INTERNET, formData.refInternet)}
      ${Components.checkbox('refAdvertisement', Texts.FROM_AD, formData.refAdvertisement)}
      ${Components.checkbox('refCareerAdvisor', Texts.FROM_CAREER_ADVISOR, formData.refCareerAdvisor)}
      ${Components.checkbox('refGigsRemote', Texts.FROM_FRIEND_IN_GIGSREMOTE, formData.refGigsRemote)}
      ${Components.checkbox('refOther', Texts.OTHER, formData.refOther)}
      <label id="freelancer-signup-error" class="error"></label>
      <nav>
        ${Components.backFormButton('back', Texts.BACK)}
        ${Components.nextFormButton('finish', Texts.SUBMIT, 'freelancer')}
      </nav>`);
    Forms.updateRequiredFields('freelancer-signup', Validation.resolveRequiredFields(thirdStepRules));

    function read(): void {
      Forms.read('freelancer-signup', formData);
      formData.motivFlexability = Forms.convertCheckboxVal(formData.motivFlexability);
      formData.motivExtraMoney = Forms.convertCheckboxVal(formData.motivExtraMoney);
      formData.motivLocation = Forms.convertCheckboxVal(formData.motivLocation);
      formData.motivProjects = Forms.convertCheckboxVal(formData.motivProjects);
      formData.motivOfficeDynamics = Forms.convertCheckboxVal(formData.motivOfficeDynamics);
      formData.motivPursuePassions = Forms.convertCheckboxVal(formData.motivPursuePassions);
      formData.motivOwnBoss = Forms.convertCheckboxVal(formData.motivOwnBoss);
      formData.refFriend = Forms.convertCheckboxVal(formData.refFriend);
      formData.refLinkedIn = Forms.convertCheckboxVal(formData.refLinkedIn);
      formData.refFacebook = Forms.convertCheckboxVal(formData.refFacebook);
      formData.refInternet = Forms.convertCheckboxVal(formData.refInternet);
      formData.refAdvertisement = Forms.convertCheckboxVal(formData.refAdvertisement);
      formData.refCareerAdvisor = Forms.convertCheckboxVal(formData.refCareerAdvisor);
      formData.refGigsRemote = Forms.convertCheckboxVal(formData.refGigsRemote);
      formData.refOther = Forms.convertCheckboxVal(formData.refOther);
    }

    DOM.onIdClick('back', () => {
      read();
      displaySecondStep();
    });
    DOM.onIdClick<HTMLButtonElement>('finish', (_, btn) => {
      read();
      validate(thirdStepRules, () => { send(btn); });
    });
  }

  function displayLastStep(): void {
    Forms.replaceContent('freelancer-signup', `
      <p class="normal-bold">${Texts.THANKS_FOR_SUBMITTING_YOUR_DETAILS}</p>
      <p class="normal-bold">${Texts.PLEASE_CHECK_YOUR_INBOX_}</p>
      ${Components.button('close-btn', Texts.CLOSE, 'freelancer')}
    `, 'final-state');
    DOM.onIdClick('close-btn', Components.closeOverlayWithBack);
  }

  function send(btn: HTMLButtonElement): void {
    ReCaptcha.getToken('freelancer_signup', token => {
      Components.execute(btn, Repo.register(formData, token))
        .onSuccess(displayLastStep)
        .onFailure(409, (_, err) => { Forms.setError('freelancer-signup', BackendError.map(err)); })
        .onFailure(413, () => { Forms.setError('freelancer-signup', Texts.ERROR_FILE_SIZE_5MB);})
        .setDefaultFailureListener((_, err) => { Forms.handleBackendError('freelancer-signup', err); });
    });
  }

  function validate(rules: ValidationRule[], onNoErrors: Function): void {
    const errors = Validation.run(formData, rules);
    if (errors.length) {
      Forms.handle('freelancer-signup', errors);
    } else {
      onNoErrors();
    }
  }

  function loadRules(): void {
    CommonRepo.getRules('FREELANCER_REGISTER').onSuccess(rules => {
      for (const rule of rules) {
        if (rule.fieldName === 'firstName') {
          rule.msgOverride = Texts.ERROR_FIRST_NAME;
        } else if (rule.fieldName === 'lastName') {
          rule.msgOverride = Texts.ERROR_LAST_NAME;
        } else if (rule.fieldName === 'email') {
          rule.msgOverride = Texts.ERROR_EMAIL;
        } else if (rule.fieldName === 'phone') {
          rule.msgOverride = Texts.ERROR_PHONE_SHORT;
        } else if (rule.fieldName === 'termsAccepted') {
          rule.msgOverride = Texts.ERROR_COMPANY_SIGNUP_TERMS_ACCEPTED;
        } else if (rule.fieldName === 'country') {
          rule.msgOverride = Texts.ERROR_COUNTRY_OF_RESIDENCE;
        } else if (rule.fieldName === 'town') {
          rule.msgOverride = Texts.ERROR_TOWN;
        }
      }
      firstStepRules = rules.filter(r => ['firstName', 'lastName', 'email', 'phone', 'termsAccepted'].includes(r.fieldName));
      secondStepRules = rules.filter(r => ['country', 'town', 'linkedIn', 'resume', 'resumeFileName'].includes(r.fieldName));
      thirdStepRules = rules.filter(r => ['motivation', 'refFriend', 'refInternet', 'refAdvertisement', 'refOther'].includes(r.fieldName));
      Forms.updateRequiredFields('freelancer-signup', Validation.resolveRequiredFields(firstStepRules));
    });
  }

  function setupFileHandling(): void {
    const resumeInput: HTMLInputElement = DOM.byId('cv');
    const resumeError: HTMLLabelElement = DOM.byId('cv-error');
    const resumeStatus: HTMLElement = DOM.byId('resume-current');

    function setError(error: string): void {
      resumeError.innerText = error;
      formData.resume = undefined;
      formData.resumeFileName = undefined;
      lastFile.update('', 0);
      resumeStatus.innerText = '';
    }

    function updateFile(name: string, content: string, size: number): void {
      resumeError.innerText = '';
      formData.resume = content;
      formData.resumeFileName = name;
      lastFile.update(name, size);
      resumeStatus.innerHTML = lastFile.display();
    }

    resumeInput.addEventListener('change', () => {
      if (resumeInput.files && resumeInput.files.length > 0) {
        const firstFile = resumeInput.files.item(0) as File;
        if (firstFile.size > 5 * 1024 * 1024) {
          setError(Texts.ERROR_FILE_SIZE_5MB);
        } else {
          Forms.processFile(firstFile, (name, data) => {
            if (data && name) {
              Repo.verifyFile(name, data).onSuccess(() => {
                updateFile(name, data, firstFile.size);
              }).setDefaultFailureListener((_, err) => {
                setError(BackendError.map(err));
              });
            }
          });
        }
      }
    });
  }
}

type Freelancer = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  town: string;
  linkedIn: string;
  resume?: string;
  resumeFileName?: string;
  motivFlexability: boolean;
  motivExtraMoney: boolean;
  motivLocation: boolean;
  motivProjects: boolean;
  motivOfficeDynamics: boolean;
  motivPursuePassions: boolean;
  motivOwnBoss: boolean;
  refFriend: boolean;
  refLinkedIn: boolean;
  refFacebook: boolean;
  refInternet: boolean;
  refAdvertisement: boolean;
  refCareerAdvisor: boolean;
  refGigsRemote: boolean;
  refOther: boolean;
  termsAccepted: boolean;
}

class FileUpload {
  private name: string;
  private size: number;

  update(name: string, size: number): void {
    this.name = name;
    this.size = size;
  }

  display(): string {
    if (!this.name || !this.size) {
      return '';
    }
    return `${Texts.ATTACHED}: <span class="attachment">${this.resolveIcon()} <span class="name">${this.name}</span> ( ${this.convertSize()} )</span>`;
  }

  private resolveIcon(): string {
    let fileIcon = 'file.svg';
    const dotIdx = this.name.lastIndexOf('.');
    if (dotIdx !== -1) {
      const ext = this.name.substring(dotIdx + 1).toLocaleLowerCase();
      if (['html', 'htm', 'xhtm', 'xhtml', 'xml'].includes(ext)) {
        fileIcon = 'file-code.svg';
      } else if (['xls', 'xlsx', 'ods'].includes(ext)) {
        fileIcon = 'file-excel.svg';
      } else if (['png', 'jpg', 'jpeg'].includes(ext)) {
        fileIcon = 'file-image.svg';
      } else if ('pdf' === ext) {
        fileIcon = 'file-pdf.svg';
      } else if (['ppt', 'pptx'].includes(ext)) {
        fileIcon = 'file-powerpoint.svg';
      } else if (['doc', 'docx', 'rtf', 'odt'].includes(ext)) {
        fileIcon = 'file-word.svg';
      }
    }
    return `<img src="./${fileIcon}" />`;
  }

  private convertSize(): string {
    if (this.size < 1024) {
      return `${this.size}B`;
    }
    if (this.size < 1024 * 1024) {
      return `${Math.floor(this.size / 1024)}KB`;
    }
    return `${Math.floor(this.size / 1024 / 1024)}MB`;
  }
}
