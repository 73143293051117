import {FreelancerCategories} from '../partials/freelancer_categories';
import {DOM} from '../core/dom';
import {Testemonials} from '../partials/testemonials';
import {FreelancerSignup} from '../core/freelancer-signup';
// import {RelatedCompanies} from '../partials/related_companies';
import {Benefits} from '../partials/benefits';
import {CompanySignup} from '../core/company-signup';
import {HomeRelated} from '../partials/home_related';
import { CategoriesData } from '../core/categoriesData';

DOM.onReady(() => {
  FreelancerCategories.onload('extended', CategoriesData.getHomeRoles());
  HomeRelated.onload();
  Testemonials.onload();
  FreelancerSignup.onload();
  // RelatedCompanies.onload();
  Benefits.onload('company', CompanySignup.display);
  Benefits.onload('freelancer', FreelancerSignup.display);
});