import './slider.sass';

import Splide, { Options } from '@splidejs/splide';
import { DOM } from './dom';

export type Slider = {
  onChange(callback: (idx: number) => void): void;
}

export namespace Slider {
  export function setup(id: string, type: 'company' | 'freelancer' | 'plain', options?: Options): Slider {
    const elem = DOM.byId(id);
    elem.classList.add('gigs-slider', type);
    options = options || {type: 'loop'};
    options.classes = {
      prev: 'splide__arrow--prev gr-icon gri-chav-left',
      next: 'splide__arrow--next gr-icon gri-chav-right'
    }
    const splide = new Splide(elem, options).mount();
    return {
      onChange(callback): void {
        splide.on('moved', callback);
      }
    }
  }

  export function create(id: string, slides: string[]): string {
    return `
      <div id="${id}" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            ${slides.map(s => `<li class="splide__slide">${s}</li>`).join('')}
          </ul>
        </div>
      </div>`;
  }
}