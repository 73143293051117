import { CommonRepo, Role, Position } from '../core/common-repository';
import { DOM } from '../core/dom';
import { Slider } from '../core/slider';
import { Texts } from '../core/texts';
import { BREAKPOINT_DESKTOP, BREAKPOINT_SMALL_DESKTOP, BREAKPOINT_TABLET } from '../variables';

const BREAKPOINT_SETTINGS: {[maxWidth: number]: { perPage: number; }} = {};
BREAKPOINT_SETTINGS[BREAKPOINT_TABLET] = { perPage: 1 };
BREAKPOINT_SETTINGS[BREAKPOINT_SMALL_DESKTOP] = { perPage: 2 };
BREAKPOINT_SETTINGS[BREAKPOINT_DESKTOP] = { perPage: 3 };

export namespace FreelancerCategories {
  
  function setupRoles(container: HTMLElement, view: 'extended' | 'compact', roles: Role[]) {
    container.innerHTML = render(roles, view);
    setToggleListeners(roles);
    setupSliders(roles);
  }
  
  export function onload(view: 'extended' | 'compact' = 'compact', roles?: Role[]): void {
    const container = DOM.byId('freelancer-categories');
    container.classList.add(view);
    if(roles) {
      return setupRoles(container, view, roles)
    }
    CommonRepo.getRoles().onSuccess(res => {
      setupRoles(container, view, res)
    });
  }

  function render(roles: Role[], view: 'extended' | 'compact'): string {
    let content = view === 'extended' ? `<h1 class="medium">${Texts.A_COMMUNITY_OF_IT_PROFESSIONALS_}</h1>` : '';
    content += `<div class="categories-container" style="grid-template-rows: ${roles.map(() => 'auto').slice(0, -1).join(' ') + ' 1fr'};">`;
    let extraClass = 'active';
    for (const role of roles) {
      content += `
        <div id="role-${role.id}" class="role"><span class="underlined ${extraClass}"><h5 class="no-margin">${role.name}</h5></span></div>
        <div id="for-role-${role.id}" class="positions ${extraClass}" style="grid-row: 1 / span ${roles.length}">
          ${createPositionsSlider(`role-slider-${role.id}`, role.positions)}
        </div>`;
      extraClass = '';
    }
    return content + '</div>';
  }

  function setupSliders(roles: Role[]): void {
    for (const role of roles) {
      Slider.setup(`role-slider-${role.id}`, 'plain', { perPage: 4, breakpoints: BREAKPOINT_SETTINGS });
    }
  }

  function setToggleListeners(roles: Role[]): void {
    for (const role of roles) {
      DOM.onIdClick(`role-${role.id}`, (_, elem) => {
        DOM.removeClass('.role > .underlined, .positions', 'active');
        (elem.children.item(0) as HTMLElement).classList.add('active');
        DOM.byId(`for-role-${role.id}`).classList.add('active');
      });
    }
  }

  function createPositionsSlider(id: string, positions: Position[]): string {
    const slides: string[] = positions.map(p => `<div class="slide"><p class="normal-bold position">${p.name}</p>${p.skills.map(s => `<p class="small skill">${s.name}</p>`).join('')}</div>`);
    return Slider.create(id, slides);
  }
}