import './select.sass';

import * as ChoicesJS from 'choices.js';
import { Choices } from 'choices.js';
import { DOM } from './dom';

const choices: Choices = ChoicesJS as any;

export type Select = {
  updateOptions(options: Select.Option[]): void;
  getValue(): string[];
  setValue(val: string): void;
  setValues(val: string[]): void;
  clearStore(): void;
}

export namespace Select {
  export type Option = { label: string; value: string; placeholder?: boolean; selected?: boolean; }

  export function setup(id: string, options: Option[], allowAddNew: boolean = false, placeholder?: string): Select {
    const elem = DOM.byId(id);
    if (placeholder) {
      options.unshift({placeholder: true, selected: true, label: placeholder, value: ''});
    }
    let instance = new choices(elem, prepareOptions(options) as any);
    const replaceChoices = (replacement: Option[]) => { instance.setChoices(replacement, 'value', 'label', true); }
    if (allowAddNew) {
      const searchInput: HTMLInputElement = instance.input.element;
      searchInput.addEventListener('input', () => {
        const value = searchInput.value;
        const isPresent = !value || options.findIndex(o => o.label.toLowerCase() === value.toLowerCase()) > 0;
        const optionsToSet = isPresent ? options : [{label: value, value: value}].concat(options);
        replaceChoices(optionsToSet);
      });
    }
    const handler: Select = {
      updateOptions(updated: Option[]): void {
        options = updated;
        replaceChoices(options);
      },
      getValue(): string[] {
        return instance.getValue(true);
      },
      setValue(val: string): void {
        instance.setChoiceByValue(val);
      },
      setValues(vals: string[]): void {
        for (const val of vals) {
          instance.setChoiceByValue(val);
        }
      },
      clearStore(): void {
        instance.clearStore();
      }
    };
    return handler;
  }

  function prepareOptions(items: Option[]): Partial<ChoicesJS.Options> {
    items.unshift({label: '', value: ''});
    const options: Partial<ChoicesJS.Options> = {
      choices: items,
      removeItemButton: true,
      allowHTML: false,
      searchResultLimit: 10,
      shouldSort: false,
      position: 'bottom'
    }
    return options;
  }
}