export const Texts = {
  SEND_US_A_MESSAGE: 'Send us a message',
  GENERAL: 'General',
  FREELANCER: 'Freelancer',
  COMPANY: 'Company',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'E-mail',
  PHONE: 'Phone',
  COUNTRY: 'Country',
  COUNTRY_CODE: 'Country Code',
  MESSAGE: 'Message',
  ENTER_YOUR_MESSAGE: 'Enter your message',
  TERMS_ACCEPTED_TNC: 'Please read our <a href="./privacy.html" target="_blank" class="gigs-lnk">Privacy Policy</a> and <a href="./cookies.html" target="_blank" class="gigs-lnk">Cookies Policy</a> and confirm that you agree with those before you submit your inquiry.',
  SEND: 'Send',
  THANK_YOUR_FOR_YOUR_MESSAGE: 'Thank you for your message',
  WE_WILL_CONTACT_YOU_SOON: 'we will come back to you as soon as possible',
  ERROR_GENERAL_CONTACT: 'Failed to send message, please try again later or use another way to contact us',
  ERROR_NOT_EMPTY_OR_NULL: 'Please populate this required field',
  ERROR_REGEX: 'Please adjust the format',
  ERROR_CHECKED: 'Please check this',
  ERROR_LENGTH_MAX: 'Please reduce the entered characters to or below --MAX--',
  ERROR_LENGTH_MIN: 'Please enter at least --MIN-- characters',
  ERROR_LENGTH_EXACT: 'Please enter exactly --MIN-- characters',
  ERROR_LENGTH_BETWEEN: 'Please enter between --MIN-- and --MAX-- characters',
  ERROR_SELECT: 'Please select an option',
  ERROR_FILE_SIZE_5MB: 'Your file needs to be in one of the following formats: .pdf, .doc, .docx, .txt, .html, and should not exceed 5MB',
  ERROR_FILE_EXTENSION: 'This file type is not supported. Please upload your CV in .pdf, .doc, .docx, .txt or .html format.',
  ERROR_EMAIL_ALREADY_TAKEN: 'There is already an account with this e-mail',
  ERROR_FREELANCER_EMAIL_ALREADY_TAKEN: 'This email is already registered in our system. Please log in with your details or contact us on freelancer@gigsremote.com to help you edit your profile.',
  ERROR_FREELANCER_RESUME_OR_URL_REQUIRED: 'Please provide a resume and/or a link to your website or external profile',
  ERROR_GENERAL: 'Failed to send your request, please try again later or contact us',
  ERROR_FULL_NAME: 'Please write your full name',
  ERROR_FIRST_NAME: 'Please write your name',
  ERROR_LAST_NAME: 'Please write your last name',
  ERROR_MESSAGE: 'Please tell us more about your inquiry',
  ERROR_COUNTRY: 'Please select one of the available options',
  ERROR_COUNTRY_OF_RESIDENCE: 'Please enter your current country of residence',
  ERROR_TOWN: 'Please enter your current city',
  ERROR_COMPANY_NAME: 'Please write your company’s name',
  ERROR_EMAIL: 'Please enter a valid email address using the following format: name@example.com',
  ERROR_PHONE: 'Please write your phone number',
  ERROR_PHONE_SHORT: 'Please write your phone',
  ERROR_SHORT_DESCRIPTION: 'Please give us more information about the project you need freelancers for',
  ERROR_COMPANY_STEP_1: 'Please select what type of professionals you are looking for.',
  ERROR_COMPANY_STEP_2: 'Аpproximate number of professionals.',
  ERROR_COMPANY_STEP_3: 'Please select the type of the project(s).',
  ERROR_COMPANY_STEP_4: 'Please select what applies to the state of your product’s specification.',
  ERROR_COMPANY_STEP_5: 'Please let us know how long the engagement is going to be for.',
  ERROR_COMPANY_STEP_6: 'Please select the type of commitment you are looking for.',
  ERROR_COMPANY_STEP_7: 'Please select the skills you are looking for.',
  ERROR_COMPANY_STEP_8: 'Please choose when you would like to start the engagement.',
  ERROR_COMPANY_STEP_10: 'Please select the desired seniority.',
  ERROR_COMPANY_STEP_11: 'Please select what fits your budget from the options.',
  ERROR_COMPANY_SIGNUP_TERMS_ACCEPTED: 'Please read our Terms of Use, Privacy Policy and Cookies Policy and confirm that you agree with those before you continue the application process.',
  COMPANY_SIGNUP_NUMBER_OF_EMPLOYEES_PLACEHOLDER: 'Please indicate with a number the approximate number of professionals.',
  COMPANY_SIGNUP_SKILLS_PLACEHOLDER: 'Click the text box to see a dropdown with the available skills and select the appropriate ones.',
  FREELANCER_SIGNUP_WELCOME_TITLE: 'Welcome to GigsRemote!',
  FREELANCER_SIGNUP_WELCOME_SUB_TITLE: 'Register to our freelancers’ network in order to start looking for projects.',
  NEXT: 'Next',
  BACK: 'Back',
  FINISH: 'Finish',
  SUBMIT: 'Submit',
  CLOSE: 'Close',
  SIGN_UP_LINKEDIN: 'Sign up with LinkedIn',
  SIGN_UP_APPLE: 'Sign up with Apple',
  YOUR_LOCATION: 'Your current location',
  CITY: 'City',
  SHARE_WITH_US_YOUR_PROFESSIONAL_PROFILE: 'Share with us your professional profile',
  UPLOAD_YOUR_RESUME: 'Upload your resume',
  LINK_WEBSITE_OR_SOCIAL_PROFILE: 'Personal website or external profile ',
  LINK_WEBSITE_BENEFITS: 'You can add your personal website or social media profile. This will enable us to gain better insight about your professional experience and can shorten the application process.',
  URL: 'URL',
  WHAT_IS_YOUR_MOTIVATION_TO_JOIN: 'What is your motivation to join Gigs Remote?',
  WRITE_SHORT_ANSWER: 'Write a short answer',
  HOW_DID_YOU_HEAR_ABOUT_US: 'How did you hear about us?',
  MOTIVATION_FLEXABILITY: 'To have flexibility in my schedule',
  MOTIVATION_EXTRA_MONEY: 'To earn extra money',
  MOTIVATION_LOCATION: 'To work from the location of my choosing',
  MOTIVATION_PROJECTS: 'To be able to choose my own projects',
  MOTIVATION_OFFICE_DYNAMICS: 'To get rid from office dynamics',
  MOTIVATION_PURSUE_PASSIONS: 'To have a schedule that enables me to pursue my personal passions',
  MOTIVATION_OWN_BOSS: 'To be my own boss',
  FROM_A_FRIED: 'From a friend',
  FROM_LINKEDIN: 'From LinkedIn',
  FROM_FACEBOOK: 'From Facebook',
  FROM_INTERNET: 'I was searching for freelance project and found your website',
  FROM_AD: 'From advertisement',
  FROM_CAREER_ADVISOR: 'I was contacted by your career advisor',
  FROM_FRIEND_IN_GIGSREMOTE: 'I know someone in GigsRemote',
  OTHER: 'Other',
  THANKS_FOR_SUBMITTING_YOUR_DETAILS: 'Thanks for submitting your details!',
  PLEASE_CHECK_YOUR_INBOX_: 'Please check your inbox for a message from us and verify your email. Our career advisor will get in touch with you for the next steps.',
  ATTACHED: 'Attached',
  COMPANY_ENQUIRY_TITLE: 'Fill in the form so we can get back to you with the right fit',
  COMPANY_FREELANCER_SIGNUP_TERMS_ACCEPTED: 'Please read our <a href="./terms.html" target="_blank" class="gigs-lnk">Terms of Use</a>, <a href="./privacy.html" target="_blank" class="gigs-lnk">Privacy Policy</a> and <a href="./cookies.html" target="_blank" class="gigs-lnk">Cookies Policy</a> and confirm that you agree with those before you submit the details of your request.',
  SHORT_PROJECT_DESCRIPTION: 'Short project description',
  COMPANY_NAME: 'Company name',
  TRADING: 'Trading',
  TRADING_NAME: 'Trading name',
  NAME: 'Name',
  CONTACT_NAME: 'Contact name',
  TIMEZONE: 'Timezone',
  THANK_YOU_FOR_SENDING_YOUR_REQUEST: 'Thank you for sending your request!',
  WE_WILL_CONTACT_YOU_WITHIN_48_HOURS: 'We will contact you within 48 hours',
  MEANWHILE_FOLLOW_US_ON_SOCIAL_MEDIA_: 'Meanwhile, follow us on social media: <a href="https://www.linkedin.com/company/gigsremote/" target="_blank" class="gigs-lnk">LinkedIn</a> and <a href="https://www.facebook.com/GigsRemote-108407248501001" target="_blank" class="gigs-lnk">Facebook</a>',
  A_COMMUNITY_OF_IT_PROFESSIONALS_: 'A community of IT professionals with 5+ years experience',
  MAX_CHAR_COMMON_ERROR: (max: number) => `Max ${max} characters allowed` 
} as const;