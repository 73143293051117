import {FieldError, ValidationRule} from './common-repository';
import {Texts} from './texts';

export namespace Validation {
  export function run(data: {[fieldName: string]: any}, rules: ValidationRule[]): FieldError[] {
    return rules.map(r => execute(r, data[r.fieldName])).filter(err => err !== null) as FieldError[];
  }

  export function resolveRequiredFields(rules: ValidationRule[]): string[] {
    return rules.filter(r => r.type === 'CHECKED' || r.type === 'NOT_EMPTY' || r.type === 'LENGTH' && r.min !== undefined).map(r => r.fieldName);
  }

  function execute(rule: ValidationRule, value: any): FieldError | null {
    switch (rule.type) {
      case 'LENGTH':
        return Validators.length(value, rule.min as number, rule.max as number) ? null : {field: rule.fieldName, message: formatLengthMsg(value, rule.min, rule.max), msgOverride: rule.msgOverride};
      case 'NOT_EMPTY':
        return Validators.notEmpty(value) ? null : {field: rule.fieldName, message: Texts.ERROR_NOT_EMPTY_OR_NULL, msgOverride: rule.msgOverride};
      case 'REGEX':
        return Validators.regex(value, RegExp(rule.pattern as string)) ? null : {field: rule.fieldName, message: Texts.ERROR_REGEX, msgOverride: rule.msgOverride};
      case 'CHECKED':
        return Validators.checked(value) ? null : {field: rule.fieldName, message: Texts.ERROR_CHECKED, msgOverride: rule.msgOverride};
      case 'NOT_NULL':
        return value !== null && value !== undefined ? null : {field: rule.fieldName, message: Texts.ERROR_NOT_EMPTY_OR_NULL, msgOverride: rule.msgOverride};
      case 'NUMBER_RANGE':
        return Validators.numberRange(value, rule.min, rule.max) ? null : {field: rule.fieldName, message: Texts.ERROR_NOT_EMPTY_OR_NULL, msgOverride: rule.msgOverride};
      default:
        // skip any unsupported rule types
        return null;
    }
  }

  function formatLengthMsg(val: string, min?: number, max?: number): string {
    if (!val && min) {
      return Texts.ERROR_NOT_EMPTY_OR_NULL;
    }
    if (!min) {
      return Texts.ERROR_LENGTH_MAX.replace('--MAX--', max + '');
    }
    if (!max) {
      return Texts.ERROR_LENGTH_MIN.replace('--MIN--', min + '');
    }
    if (min === max) {
      return Texts.ERROR_LENGTH_EXACT.replace('--MIN--', min + '');
    }
    return Texts.ERROR_LENGTH_BETWEEN.replace('--MIN--', min + '').replace('--MAX--', max + '');
  }
}

namespace Validators {
  export function length(val: string, min: number, max: number): boolean {
    if (min && (!val || val.trim().length < min)) {
      return false;
    }
    if (max && val && val.trim().length > max) {
      return false;
    }
    return true;
  }

  export function notEmpty(val: string): boolean {
    return val ? val.trim().length > 0 : false;
  }

  export function regex(val: string, regex: RegExp): boolean {
    return val ? regex.test(val) : false;
  }

  export function checked(val: boolean): boolean {
    return val;
  }

  export function numberRange(val: string, min?: number, max?: number): boolean {
    const number = parseInt(val);
    if (min !== undefined && (!val || Number.isNaN(number)) || number < (min as number)) {
      return false;
    }
    if (max !==undefined && !Number.isNaN(number) && number > (max as number)) {
      return false;
    }
    return true;
  }
}