import './company-signup.sass';

import {Ajax} from './ajax';
import {Components} from './components';
import {Forms} from './forms';
import {DOM} from './dom';
import {BackendError, CommonRepo, Skill, ValidationRule} from './common-repository';
import {Select} from './select';
import {ReCaptcha} from './recaptcha';
import { RangePicker } from './range-picker';
import { Validation } from './validation';
import { Texts } from './texts';

type AvailableAnswer = {
  id: number;
  questionId: number;
  value: string;
  priority: number;
}

type Question = {
  id: number;
  quizId: number;
  question: string;
  label: string;
  priority: number;
  answerType: 'SELECTABLE' | 'NUMBER' | 'SELECTABLE_NOMENCLATURE' | 'TIME_INTERVAL';
  multiSelect: boolean;
  optional: boolean;
  availableAnswers: AvailableAnswer[];
}

type QuizStart = {
  sessionId: string;
  customerQuizId: number;
  firstQuestion: Question;
  totalQuestions: number;
}

type Answer = {
  answer?: any;
  selectableAnswerId?: number;
}

type AnswerTimeInterval = {
  fromHour: number;
  toHour: number;
  timezone: string;
}

type Company = {
  registrationQuizSessionId: string;
  contactName: string;
  companyName: string;
  tradingName: string;
  email: string;
  phone: string;
  projectDescription: string;
  termsAccepted: boolean;
}

type TimeZone = {
  id: string;
  displayName: string;
}

namespace Repo {
  export function start(): Ajax.Callbacks<QuizStart, void> {
    return Ajax.post('v1/customer-quiz/start', {quizLabel: 'company_registration'});
  }

  export function submit(sessionId: string, answers: Answer[]): Ajax.Callbacks<Question, BackendError> {
    return Ajax.post('v1/customer-quiz/submit', {sessionId: sessionId, quizAnswers: answers});
  }

  export function resubmit(sessionId: string, questionId: number, answers: Answer[]): Ajax.Callbacks<void, BackendError> {
    return Ajax.post('v1/customer-quiz/resubmit', {sessionId: sessionId, questionId: questionId, quizAnswers: answers});
  }

  export function finilize(token: string, company: Company): Ajax.Callbacks<void, BackendError> {
    return Ajax.post('v1/companies/register', company, [{name: 'X-Token', value: token}]);
  }

  export function getTimeZones(): Ajax.Callbacks<TimeZone[], void> {
    return Ajax.get('v1/timezones');
  }
}

export namespace CompanySignup {
  let sessionId: string;
  let selectedPosition: string = '';
  let selectSkills: Select;
  let rules: ValidationRule[] = [];
  let quiz: {q: Question, a?: Answer[]}[] = [];
  let currentQuestionIdx: number = 1;
  let totalQuestions: number;

  export function display(): void {
    CommonRepo.getRules('COMPANY_REGISTER').onSuccess(r => {
      for (const rule of r) {
        if (rule.fieldName === 'contactName') {
          rule.msgOverride = Texts.ERROR_FULL_NAME;
        } else if (rule.fieldName === 'companyName') {
          rule.msgOverride = Texts.ERROR_COMPANY_NAME;
        } else if (rule.fieldName === 'email') {
          rule.msgOverride = Texts.ERROR_EMAIL;
        } else if (rule.fieldName === 'phone') {
          rule.msgOverride = Texts.ERROR_PHONE;
        } else if (rule.fieldName === 'projectDescription') {
          rule.msgOverride = Texts.ERROR_SHORT_DESCRIPTION;
        } else if (rule.fieldName === 'termsAccepted') {
          rule.msgOverride = Texts.ERROR_COMPANY_SIGNUP_TERMS_ACCEPTED;
        }
      }
      rules = r;
    });
    Components.showOverlay('<form id="company-signup"></form>');
    history.pushState({ modal: 'company-contact' }, "", "#contact");
    window.onpopstate = (() => {
      Components.closeOverlay();
    });
    Repo.start().onSuccess(resp => {
      sessionId = resp.sessionId;
      totalQuestions = resp.totalQuestions;
      quiz = [];
      currentQuestionIdx = 1;
      addQuestion(resp.firstQuestion);
      render(resp.firstQuestion);
    });
  }

  function addQuestion(q: Question): void {
    quiz[currentQuestionIdx] = { q: q };
  }

  function addAnswer(answers: Answer[]): void {
    quiz[currentQuestionIdx].a = answers;
  }

  function submitAnswer(question: Question, answers: Answer[], btn: HTMLButtonElement): void {
    if (!quiz[currentQuestionIdx].a) {
      Components.execute(btn, Repo.submit(sessionId, answers))
        .onSuccess(nextQuestion => {
          addAnswer(answers);
          currentQuestionIdx += 1;
          addQuestion(nextQuestion);
          render(nextQuestion);
        })
        .onFailure((_, err) => { Forms.handleBackendError('company-signup', err); });
    } else {
      Components.execute(btn, Repo.resubmit(sessionId, question.id, answers))
        .onSuccess(() => {
          addAnswer(answers);
          render(quiz[++currentQuestionIdx].q);
        })
        .onFailure((_, err) => { Forms.handleBackendError('company-signup', err); });
    }
  }

  function render(q: Question): void {
    if (q) {
      const content = `
        ${Components.steps(totalQuestions, currentQuestionIdx, 'company')}
        ${renderAnswers(q)}
        <label id="company-signup-error" class="error"></label>
        <nav>
          ${currentQuestionIdx > 1 ? Components.backFormButton('back', Texts.BACK) : ''}
          ${Components.nextFormButton('next', Texts.NEXT, 'company')}
        </nav>`;
      Forms.replaceContent('company-signup', content);
      afterAppend(q);
      if (currentQuestionIdx > 1) {
        DOM.onIdClick<HTMLButtonElement>('back', (_, btn) => {
          render(quiz[--currentQuestionIdx].q);
        });
      }
      DOM.onIdClick<HTMLButtonElement>('next', (_, btn) => {
        const answers = collectAnswers(q);
        validateAnswer(q, answers, () => {
          submitAnswer(q, answers, btn);
          if (currentQuestionIdx === 1) {
            const selectionId = answers[0].selectableAnswerId;
            selectedPosition = q.availableAnswers.filter(a => a.id == selectionId).map(a => a.value).join('');
          } else if (currentQuestionIdx === 2) {
            const isPlural = answers[0].answer != 1;
            if (isPlural) {
              selectedPosition = selectedPosition.endsWith('s') ? selectedPosition : selectedPosition + 's';
            } else {
              selectedPosition = selectedPosition.endsWith('s') ? selectedPosition.substring(0, selectedPosition.length - 1) : selectedPosition;
            }
          }
        });
      });
    } else {
      const descriptionMaxChars = rules.find(r => r.fieldName === 'projectDescription')?.max || Number.MAX_SAFE_INTEGER;
      const content = `
        <p class="normal-bold">${Texts.COMPANY_ENQUIRY_TITLE}</p>
        ${Components.input('contactName', Texts.CONTACT_NAME, Texts.NAME)}
        ${Components.input('companyName', Texts.COMPANY_NAME, Texts.COMPANY)}
        ${Components.input('email', Texts.EMAIL, Texts.EMAIL, '', 'email')}
        ${Components.input('phone', Texts.PHONE, Texts.PHONE, '', 'tel')}
        ${Components.textarea({
        name: 'projectDescription',
        label: Texts.SHORT_PROJECT_DESCRIPTION,
        placeholder: Texts.SHORT_PROJECT_DESCRIPTION,
        maxChars: descriptionMaxChars
      })}
        ${Components.checkbox('termsAccepted', Texts.COMPANY_FREELANCER_SIGNUP_TERMS_ACCEPTED)}
        <label id="company-signup-error" class="error"></label>
        <nav>
          ${Components.nextFormButton('next', Texts.NEXT, 'company')}
        </nav>
      `;
      Forms.replaceContent('company-signup', content);
      Forms.handleMaxCharCounter('projectDescription', descriptionMaxChars);
      Forms.updateRequiredFields('company-signup', Validation.resolveRequiredFields(rules));
      DOM.onIdClick<HTMLButtonElement>('next', (_, btn) => {
        const company: Company = Forms.read('company-signup');
        company.termsAccepted = Forms.convertCheckboxVal(company.termsAccepted);
        company.registrationQuizSessionId = sessionId;
        validate(company, rules, () => {
          ReCaptcha.getToken('company_signup', token => {
            Components.execute(btn, Repo.finilize(token, company))
              .onSuccess(finilizeForm)
              .onFailure(409, (_, err) => {
                if (err && err.code === 'EMAIL_ALREADY_EXISTS') {
                  Forms.clearErrors('company-signup');
                  DOM.byId('email-error').innerText = Texts.ERROR_EMAIL_ALREADY_TAKEN;
                } else {
                  Forms.setError('company-signup', BackendError.map(err));
                }
              })
              .onFailure((_, err) => { Forms.handleBackendError('company-signup', err); });
          });
        });
      })
    }
  }

  function renderAnswers(q: Question): string {
    const questionText = q.question.replace('{{ROLE_NAME}}', selectedPosition);
    const answers = quiz[currentQuestionIdx].a;
    if (q.answerType === 'SELECTABLE') {
      const selected = answers ? answers.map(a => a.selectableAnswerId + '')[0] : undefined;
      return `
        <p class="normal-bold">${questionText}</p>
        ${Components.radio(q.label, q.availableAnswers.map(a => { return {name: a.value, value: a.id + '', label: a.value}; }), selected)}`;
    } else if (q.answerType === 'NUMBER') {
      const value = answers ? answers.map(a => a.answer)[0] : '';
      const placeholder = q.label === 'how_many_people_you_need' ? Texts.COMPANY_SIGNUP_NUMBER_OF_EMPLOYEES_PLACEHOLDER : questionText;
      return Components.input(q.label, questionText, placeholder, value, 'number');
    } else if (q.answerType === 'SELECTABLE_NOMENCLATURE') {
      return Components.select(q.label, questionText, questionText, true);
    } else if (q.answerType === 'TIME_INTERVAL') {
      return `
        <p class="normal-bold">${questionText}</p>
        <div id="hour"></div>
        ${Components.select('timezone', Texts.TIMEZONE, '')}`;
    }
    return '';
  }

  function afterAppend(q: Question): void {
    const answers = quiz[currentQuestionIdx].a;
    if (q.answerType === 'SELECTABLE_NOMENCLATURE') {
      CommonRepo.getRoles().onSuccess(roles => {
        const nameToSkill: {[name: string]: Skill} = {};
        for (let role of roles) {
          for (let position of role.positions) {
            for (let skill of position.skills) {
              nameToSkill[skill.name] = skill;
            }
          }
        }
        const allSkills: Skill[] = [];
        for (let name in nameToSkill) {
          allSkills.push(nameToSkill[name]);
        }
        let options = allSkills.map(s => { return {label: s.name, value: s.id + ''}; });
        if (answers) {
          const userDefined = answers.filter(a => a.answer);
          if (userDefined.length) {
            options = options.concat(userDefined.map(a => { return {label: a.answer, value: a.answer}; }));
          }
        }
        selectSkills = Select.setup(q.label, options, true);
        if (answers) {
          selectSkills.setValues(answers.map(a => a.selectableAnswerId ? a.selectableAnswerId + '' : a.answer));
        }
        DOM.bySelector('input[name=search_terms]', elem => {
          const search = elem as HTMLInputElement;
          search.addEventListener('blur', () => { search.value = ''; });
        });
      });
    } else if (q.answerType === 'TIME_INTERVAL') {
      Repo.getTimeZones().onSuccess(zones => {
        const zonesSelect = Select.setup('timezone', zones.map(z => { return {label: z.displayName, value: z.id}; }), false, Texts.TIMEZONE);
        if (answers) {
          const answerTime: AnswerTimeInterval = answers[0].answer as AnswerTimeInterval;
          zonesSelect.setValue(answerTime.timezone);
          RangePicker.setValue(DOM.byId('hour'), [answerTime.fromHour, answerTime.toHour]);
        } else {
          setCurrentTimeZone(zonesSelect);
        }
      });
      RangePicker.setup(DOM.byId('hour'));
    }
  }

  function collectAnswers(q: Question): Answer[] {
    const data = Forms.read('company-signup') as any;
    if (q.answerType === 'SELECTABLE') {
      return [{selectableAnswerId: data[q.label]}];
    } else if (q.answerType === 'NUMBER') {
      return [{answer: data[q.label]}];
    } else if (q.answerType === 'SELECTABLE_NOMENCLATURE') {
      return selectSkills.getValue().map(v => {
        const id = parseInt(v);
        return Number.isNaN(id) ? {answer: v} : {selectableAnswerId: id};
      });
    } else if (q.answerType === 'TIME_INTERVAL') {
      const timeInterval: AnswerTimeInterval = data as AnswerTimeInterval;
      const fromTo: number[] = RangePicker.getValue(DOM.byId('hour'));
      timeInterval.fromHour = fromTo[0];
      timeInterval.toHour = fromTo[1];
      return [{answer: timeInterval}];
    }
    return [];
  }

  function validateAnswer(q: Question, a: Answer[], onNoErrors: Function): void {
    if (q.optional) {
      onNoErrors();
    } else if (q.answerType === 'SELECTABLE') {
      validate(a[0] || {}, [{fieldName: 'selectableAnswerId', type: 'NOT_NULL', msgOverride: resolveErrorOverride(q)}], onNoErrors);
    } else if (q.answerType === 'SELECTABLE_NOMENCLATURE') {
      if (a.length) {
        onNoErrors();
      } else {
        Forms.setError('company-signup', resolveErrorOverride(q) as string);
      }
    } else if (q.answerType === 'NUMBER') {
      validate(a[0] || {}, [{fieldName: 'answer', type: 'NUMBER_RANGE', min: 1, max: 1000, msgOverride: resolveErrorOverride(q)}], onNoErrors);
    } else if (q.answerType === 'TIME_INTERVAL') {
      validate((a[0] || {}).answer, [{fieldName: 'timezone', type: 'NOT_EMPTY'}], onNoErrors);
    } else {
      onNoErrors();
    }
  }

  function resolveErrorOverride(q: Question): string | undefined {
    switch (q.label) {
      case 'looking_for':                 return Texts.ERROR_COMPANY_STEP_1;
      case 'how_many_people_you_need':    return Texts.ERROR_COMPANY_STEP_2;
      case 'projects_hiring':             return Texts.ERROR_COMPANY_STEP_3;
      case 'product_specifications':      return Texts.ERROR_COMPANY_STEP_4;
      case 'how_long_you_need_designer':  return Texts.ERROR_COMPANY_STEP_5;
      case 'level_of_commitment':         return Texts.ERROR_COMPANY_STEP_6;
      case 'skills':                      return Texts.ERROR_COMPANY_STEP_7;
      case 'start_date':                  return Texts.ERROR_COMPANY_STEP_8;
      case 'team_contribution':           return Texts.ERROR_COMPANY_STEP_10;
      case 'role_budget':                 return Texts.ERROR_COMPANY_STEP_11;
    }
  }

  function finilizeForm(): void {
    Forms.replaceContent('company-signup', `
      <p class="normal-bold">${Texts.THANK_YOU_FOR_SENDING_YOUR_REQUEST}</p>
      <p class="normal-bold">${Texts.WE_WILL_CONTACT_YOU_WITHIN_48_HOURS}</p>
      <p class="normal-bold">${Texts.MEANWHILE_FOLLOW_US_ON_SOCIAL_MEDIA_}</p>
      ${Components.button('close-form', Texts.CLOSE, 'company')}`,
      'final-state');
    DOM.onIdClick('close-form', Components.closeOverlay);
  }

  function setCurrentTimeZone(zonesSelect: Select): void {
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      zonesSelect.setValue(timeZone);
    } catch (e) {
      // This way of resolving timezone is not supported in some browsers
    }
  }

  function validate(data: any, rules: ValidationRule[], onNoErrors: Function): void {
    const errors = Validation.run(data, rules);
    if (errors.length) {
      Forms.handle('company-signup', errors);
    } else {
      onNoErrors();
    }
  }
}