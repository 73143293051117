import {Ajax} from './ajax';
import { Texts } from './texts';

export type ErrorCode = 'INVALID_FILE_EXTENSION' | 'INVALID_FILE_TYPE' | 'EMAIL_ALREADY_EXISTS';
export type FieldError = { field: string; message: string; msgOverride?: string; }
export type BackendError = { code: string; message: string; errors: FieldError[] | FieldError; }
export namespace BackendError {
  export function map(error: BackendError): string {
    if (error && error.code) {
      switch (error.code) {
        case 'INVALID_FILE_EXTENSION':
        case 'INVALID_FILE_TYPE':
          return Texts.ERROR_FILE_EXTENSION;
        case 'EMAIL_ALREADY_EXISTS':
          return Texts.ERROR_EMAIL_ALREADY_TAKEN;
      }
      if (error.message) {
        return error.message;
      }
    }
    return Texts.ERROR_GENERAL;
  }
}

export type City = { name: string; }
export type Country = { name: string; countryISO3code: string; countryISO2code: string; }
export type CountryWithCities = Country & { cities: City[]; }

export type Skill = { id: number; name: string; }
export type Position = { id: number; name: string; roleId: number; skills: Skill[]; }
export type Role = { id: number; name: string; positions: Position[]; }

export type ValidationRule = {
  fieldName: string;
  type: 'LENGTH' | 'NOT_EMPTY' | 'REGEX' | 'CHECKED' | 'NOT_NULL' | 'NUMBER_RANGE';
  min?: number;
  max?: number;
  pattern?: string;
  msgOverride?: string;
}

type RuleGroup = 'CONTACT_COMPANY' | 'CONTACT_FREELANCER' | 'CONTACT_GENERAL' | 'FREELANCER_REGISTER' | 'COMPANY_REGISTER';

export namespace CommonRepo {
  export function getCountries(): Ajax.Callbacks<Country[], void> {
    return Ajax.get('v1/countries');
  }

  export function getTowns(countryCode: string): Ajax.Callbacks<CountryWithCities, void> {
    return Ajax.get('v1/countries/' + countryCode);
  }

  export function getRoles(): Ajax.Callbacks<Role[], unknown> {
    return Ajax.get('v1/roles?includePositions=true&includeSkills=true');
  }

  export function getRules(group: RuleGroup): Ajax.Callbacks<ValidationRule[], void> {
    return Ajax.get('v1/validations/rules/' + group);
  }

  export function checkEmail(email: string, token: string): Ajax.Callbacks<void, BackendError> {
    return Ajax.post(`v1/validations/email/existing/${encodeURIComponent(email)}`, undefined, [{name: 'X-Token', value: token}]);
  }
}