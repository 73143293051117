import { Role } from './common-repository';


const first100Roles: Role[] = [
  {
    id: 1,
    name: 'Software Developers',
    positions: [
      {
        id: 11,
        name: 'Backend',
        roleId: 1,
        skills: [
          {id: 111, name: 'Java'},
          {id: 112, name: 'C#'},
          {id: 113, name: 'C++'},
          {id: 114, name: 'Python'},
          {id: 115, name: 'PHP'},
          {id: 116, name: 'JavaScript'},
          {id: 117, name: 'NodeJs'},
          {id: 118, name: 'Go'}
        ]
      },
      {
        id: 12,
        name: 'Front-end',
        roleId: 1,
        skills: [
          {id: 121, name: 'JavaScript'},
          {id: 122, name: 'CSS'},
          {id: 123, name: 'HTML'},
          {id: 124, name: 'Angular'},
          {id: 125, name: 'React'},
          {id: 126, name: 'Node.JS'}
        ]
      },
      {
        id: 13,
        name: 'Full stack',
        roleId: 1,
        skills:[
          {id: 131, name: 'Java'},
          {id: 132, name: 'C#'},
          {id: 133, name: 'C++'},
          {id: 134, name: 'Python'},
          {id: 135, name: 'PHP'},
          {id: 136, name: 'JavaScript'},
          {id: 137, name: 'NodeJs'},
          {id: 138, name: 'Go'},
          {id: 139, name: 'CSS'},
          {id: 1310, name: 'HTML'},
          {id: 1311, name: 'Angular'},
          {id: 1312, name: 'React'},
        ]
      },
      {
        id: 14,
        name: 'Mobile',
        roleId: 1,
        skills: [
          {id: 141, name: 'iOS'},
          {id: 142, name: 'Android'},
          {id: 143, name: 'Ionic'},
          {id: 144, name: 'Flutter'},
          {id: 145, name: 'React Native'},
          {id: 146, name: 'Xamarin'},
          {id: 147, name: 'Swift'},
          {id: 148, name: 'Kotlin'}
        ]
      },
      {
        id: 15,
        name: 'Machine Learning and Al',
        roleId: 1,
        skills: [
          {id: 151, name: 'Python'},
          {id: 152, name: 'R'},
          {id: 153, name: 'Java'},
          {id: 154, name: 'JavaScript'},
          {id: 155, name: 'C++'},
          {id: 156, name: 'Shell'},
          {id: 157, name: 'Go'}
        ]
      },
      {
        id: 16,
        name: 'Database',
        roleId: 1,
        skills: [
          {id: 161, name: 'SQL'},
          {id: 162, name: 'MySQL'},
          {id: 163, name: 'Oracle'},
          {id: 164, name: 'MS SQL Server'},
          {id: 165, name: 'PostgreSQL'},
          {id: 166, name: 'exotic DBs'}
        ]
      },
      {
        id: 17,
        name: 'Cloud',
        roleId: 1,
        skills: [
          {id: 171, name: 'AWS'},
          {id: 172, name: 'Azure'},
          {id: 173, name: 'GCP'}
        ]
      }
    ]
  },
  {
    id: 2,
    name: 'DevOps and Infrastructure',
    positions: [
      {
        id: 21,
        name: 'DevOps',
        roleId: 2,
        skills: [
          {id: 211, name: 'Selenium'},
          {id: 212, name: 'Puppet'},
          {id: 213, name: 'Chef'},
          {id: 214, name: 'Git'},
          {id: 215, name: 'Ansible'},
          {id: 216, name: 'Docker'},
          {id: 217, name: 'Nagios'},
          {id: 218, name: 'Kubernetes'},
          {id: 219, name: 'Pearl'}
        ]
      },
      {
        id: 22,
        name: 'Cloud',
        roleId: 2,
        skills: [
          {id: 221, name: 'Public'},
          {id: 222, name: 'Private'},
          {id: 223, name: 'Hybrid'},
          {id: 224, name: 'AWS'},
          {id: 225, name: 'Azure'},
          {id: 226, name: 'GCP'}
        ]
      },
      {
        id: 23,
        name: 'Infrastructure',
        roleId: 2,
        skills: [
          {id: 231, name: 'System Administration'},
          {id: 232, name: 'Linux, Windows'},
          {id: 233, name: 'Networks, Networking, Storage'},
          {id: 234, name: 'Virtualization, VMware Hyper-V'},
          {id: 235, name: 'SD-WAN, vLAN, SD-branch, SASE'}
        ]
      },
      {
        id: 24,
        name: 'Databases',
        roleId: 2,
        skills: [
          {id: 241, name: 'SQL'},
          {id: 242, name: 'MSSQL'},
          {id: 243, name: 'PostgreSQL'},
          {id: 244, name: 'PL SQL'},
          {id: 245, name: 'Aurora'},
          {id: 246, name: 'MySQL'},
          {id: 247, name: 'MongoDB'},
          {id: 248, name: 'MariaDB'}
        ]
      }
    ]
  },
  {
    id: 3,
    name: 'Consultants',
    positions: [
      {
        id: 31,
        name: 'Software architects',
        roleId: 3,
        skills: [
          { id: 311, name: 'Layered (n-tier)' },
          { id: 312, name: 'Event-driven' },
          { id: 313, name: 'Microkernel' },
          { id: 314, name: 'Microservices' },
          { id: 315, name: 'Enterprise architecture' }
        ]
      },
      {
        id: 32,
        name: 'Solution architects',
        roleId: 3,
        skills: [
          { id: 321, name: 'Application' },
          { id: 322, name: 'Data' },
          { id: 323, name: 'Solution' },
          { id: 324, name: 'Security' },
          { id: 325, name: 'Cloud' },
          { id: 326, name: 'CRM' },
          { id: 327, name: 'ERP' }
        ]
      },
      {
        id: 33,
        name: 'Cybersecurity',
        roleId: 3,
        skills: [
          { id: 331, name: 'Penetration testing' },
          { id: 332, name: 'Advanced Persistent Threat Management' },
          { id: 333, name: 'Firewall Safety and Management' },
          { id: 334, name: 'Compliance assessment' },
          { id: 335, name: 'PCI' },
          { id: 336, name: 'HIPAA' },
          { id: 337, name: 'NIST' },
          { id: 338, name: 'GLBA' },
          { id: 339, name: 'SOX' },
          { id: 3310, name: 'COBIT' },
          { id: 3311, name: 'ITIL' }
        ]
      }
    ]
  },
  {
    id: 4,
    name: 'Data Engineers',
    positions: [
      {
        id: 41,
        name: 'Data Engineers',
        roleId: 4,
        skills: [
          {id: 411, name: 'Python'},
          {id: 412, name: 'SQL'},
          {id: 413, name: 'R'},
          {id: 414, name: 'Julia'},
          {id: 415, name: 'JavaScript'},
          {id: 416, name: 'Scala'},
          {id: 417, name: 'Go'},
          {id: 418, name: 'Java'},
          {id: 419, name: 'MatLab'}
        ]
      }
    ]
  },
  {
    id: 5,
    name: 'Quality Engineers',
    positions: [
      {
        id: 51,
        name: 'Manual',
        roleId: 5,
        skills: [
          {id: 511, name: 'Bugzilla'},
          {id: 512, name: 'LoadRunner'},
          {id: 513, name: 'ZAP'},
          {id: 514, name: 'Citrus'},
          {id: 515, name: 'Jira'},
          {id: 516, name: 'Mantis'},
          {id: 517, name: 'Tessy'},
          {id: 518, name: 'TestLink'},
          {id: 519, name: 'Trac'},
          {id: 520, name: 'ALM'},
          {id: 521, name: 'Sikuli'},
          {id: 522, name: 'Jenkins'}
        ]
      },
      {
        id: 52,
        name: 'Automated',
        roleId: 5,
        skills: [
          {id: 521, name: 'Selenium'},
          {id: 522, name: 'Appium'},
          {id: 523, name: 'TestComplete'},
          {id: 524, name: 'cypress.io'},
          {id: 525, name: 'Katalon Studio'},
          {id: 526, name: 'SoapUI'},
          {id: 527, name: 'Postman'},
          {id: 528, name: 'Perfecto'}
        ]
      }
    ]
  },
  {
    id: 6,
    name: 'Product and project managers',
    positions: [
      {
        id: 61,
        name: 'Product and project managers',
        roleId: 6,
        skills: [
          {id: 611, name: 'Jira'},
          {id: 612, name: 'Agile'},
          {id: 613, name: 'Kanban'},
          {id: 614, name: 'Scrum'},
          {id: 615, name: 'Agile product / project management'},
          {id: 616, name: 'Product roadmaps'},
          {id: 617, name: 'Product strategy'},
          {id: 618, name: 'Product Ownership'},
          {id: 619, name: 'Project planning'},
          {id: 6110, name: 'Certified Scrum Product Owner'},
          {id: 6111, name: 'SDLC'},
          {id: 6112, name: 'Requirements & Specification analysis'}
        ]
      }
    ]
  }
];

const homeRoles: Role[] = [
  {
    id: 5,
    name: 'AI and Automation',
    positions: [
      {
        id: 51,
        name: 'Consultancy',
        roleId: 5,
        skills: [
          {id: 511, name: 'Large Language Model (LLM)'},
          {id: 512, name: 'Machine Learning (ML), Deep Learning (DL)'},
          {id: 513, name: 'Algorithms & Statistics'},
          {id: 514, name: 'AI Architecture '},
          {id: 515, name: 'Pilot projects and PoCs'},
          {id: 516, name: 'Models Design and Implementation'},
          {id: 517, name: 'Framework and Tech stack selection'},
        ]
      },
      {
        id: 52,
        name: 'Data',
        roleId: 5,
        skills: [
          {id: 521, name: 'Data Analysis and Engineering'},
          {id: 522, name: 'Data Visualization'},
          {id: 523, name: 'Databases'},
          {id: 524, name: 'BigData'},
          {id: 525, name: 'Data Scraping'},
          {id: 526, name: 'Pandas'},
        ]
      },
      {
        id: 53,
        name: 'Dev and ML Ops',
        roleId: 5,
        skills:[
          {id: 531, name: 'Python'},
          {id: 532, name: 'Azure ML, OpenAI, Google APIs'},
          {id: 533, name: 'LLaMA2'},
          {id: 534, name: 'Hugging Face Transformers'},
          {id: 535, name: 'Tensorflow, Pytorch, NumPy'},
          {id: 536, name: 'ML Pipelines CI/CD'},
          {id: 537, name: 'AI/ML Quality Assurance'},
          {id: 538, name: 'OpenCV'},
          {id: 539, name: 'Azure Cognitive Services'},
        ]
      },
      {
        id: 54,
        name: 'Domains',
        roleId: 5,
        skills: [
          {id: 541, name: 'Natural Language Processing (NLP)'},
          {id: 542, name: 'Computer Vision'},
          {id: 543, name: 'Image and Video Generation'},
          {id: 544, name: 'Intelligent Audio Systems'},
          {id: 545, name: 'Predictive Models'},
          {id: 546, name: 'Corporate Automation'},
        ]
      },
    ]
  },
  {
    id: 2,
    name: 'Consultancy',
    positions: [
      {
        id: 21,
        name: 'Software',
        roleId: 2,
        skills: [
          {id: 211, name: 'Layered (n-tier)'},
          {id: 212, name: 'Event-driven'},
          {id: 213, name: 'Microkernel'},
          {id: 214, name: 'Microservices'},
          {id: 215, name: 'Enterprise architecture'},
        ]
      },
      {
        id: 22,
        name: 'Solution',
        roleId: 2,
        skills: [
          {id: 221, name: 'Application'},
          {id: 222, name: 'Data'},
          {id: 223, name: 'Solution'},
          {id: 224, name: 'Security'},
          {id: 225, name: 'Cloud'},
          {id: 226, name: 'CRM'},
          {id: 227, name: 'ERP'}
        ]
      },
      {
        id: 23,
        name: 'Infrastructure',
        roleId: 2,
        skills: [
          {id: 231, name: 'CRM'},
          {id: 232, name: 'ERP'},
          {id: 233, name: 'Network enablement'},
          {id: 234, name: 'Internet connectivity'},
          {id: 236, name: 'Firewall'},
          {id: 237, name: 'Servers'},
          {id: 238, name: 'Computers'},
          {id: 239, name: 'Data centers'},
          {id: 2310, name: 'Switches'},
          {id: 2311, name: 'Hubs'},
          {id: 2312, name: 'Routers'},
          {id: 2313, name: 'Productivity applications'},
          {id: 2314, name: 'Windows'},
          {id: 2315, name: 'Linux'},
          {id: 2316, name: 'UNIX'},
        ]
      },
      {
        id: 24,
        name: 'Cyber Security',
        roleId: 2,
        skills: [
          {id: 241, name: 'Penetration testing'},
          {id: 242, name: 'Advanced Persistent Threat Management'},
          {id: 243, name: 'Firewall Safety and Management'},
          {id: 244, name: 'Compliance assessment'},
          {id: 245, name: 'PCI'},
          {id: 246, name: 'HIPAA'},
          {id: 247, name: 'NIST'},
          {id: 248, name: 'GLBA'},
          {id: 249, name: 'SOX'},
          {id: 2410, name: 'COBIT'},
          {id: 2411, name: 'ITIL'},
        ]
      }
    ]
  },
  {
    id: 1,
    name: 'Development',
    positions: [
      {
        id: 11,
        name: 'Software Development',
        roleId: 1,
        skills: [
          {id: 111, name: 'Java, C#, C++, Python, PHP, Go, JavaScript'},
          {id: 112, name: 'CSS, HTML, React, Angular, Node.JS'},
          {id: 113, name: 'iOS, Android, Flutter, React Native, Swift, Kotlin, Ionic, Xamarin, Python, Sencha Ext, jQuery Mobile'},
          {id: 114, name: 'SQL, MySQL, Oracle, MS SQL Server, PostgreSQL, exotic DBs'},
          {id: 115, name: 'AWS, Azure,GCP'},
        ]
      },
      {
        id: 12,
        name: 'DevOps and Infrastructure',
        roleId: 1,
        skills: [
          {id: 121, name: '<b>DevOps</b> - AWS, Selenium, Puppet, Chef, Git, Ansible, Docker, Nagios, Kubernetes'},
          {id: 122, name: '<b>Cloud</b> - Public, Private, Hybrid, AWS, Azure, GCP'},
          {id: 123, name: '<b>Sys Admin</b> - Linux, Windows, Networks, Storage, SD-WAN, Virtualization, vLAN, SASE'},
          {id: 124, name: '<b>Databases</b> - SQL, MSSQL, Postgres, Pl SQL, Aurora, MySQL, MongoDB, MariaDB'},
        ]
      },
      {
        id: 13,
        name: 'Data',
        roleId: 1,
        skills:[
          {id: 131, name: 'Python, SQL, R, Julia, JavaScript, Scala, Go, Java, MatLab, Pandas, TensorFlow'},
          {id: 132, name: 'Data Analytics'},
          {id: 133, name: 'Data Reporting'},
          {id: 134, name: 'Machine Learning'},
          {id: 135, name: 'Natural Language Processing'},
        ]
      },
      {
        id: 14,
        name: 'Quality',
        roleId: 1,
        skills: [
          {id: 141, name: '<b>Manual</b> - Bugzilla, LoadRunner, ZAP, Citrus, Jira, Mantis, Tessy, TestLink, Trac, ALM, Sikuli, Jenkins'},
          {id: 142, name: '<b>Automation</b> - Selenium, Appium, TestComplete, cypress.io, Katalon Studio, SoapUI, Postman, Perfecto'},
          {id: 143, name: '<b>Mobile</b> - iOS, Android, Hybrid'},
        ]
      },
    ]
  },
  {
    id: 3,
    name: 'Product and Project Management',
    positions: [
      {
        id: 31,
        name: 'Product manager',
        roleId: 3,
        skills: [
          { id: 311, name: 'Jira' },
          { id: 312, name: 'Scrum' },
          { id: 313, name: 'Agile product management' },
          { id: 314, name: 'Product management' },
          { id: 315, name: 'Agile' },
          { id: 316, name: 'Product roadmaps' },
          { id: 317, name: 'Product strategy' },
          { id: 318, name: 'Certified Scrum Product Owner' },
        ]
      },
      {
        id: 32,
        name: 'Project manager',
        roleId: 3,
        skills: [
          { id: 321, name: 'Jira' },
          { id: 322, name: 'Agile' },
          { id: 323, name: 'Agile project management' },
          { id: 324, name: 'Project management' },
          { id: 325, name: 'Technical project management' },
          { id: 326, name: 'Scrum master' },
          { id: 327, name: 'Project planning' },
          { id: 328, name: 'Kanban' },
        ]
      },
      {
        id: 33,
        name: 'Product owner',
        roleId: 3,
        skills: [
          { id: 331, name: 'Jira' },
          { id: 332, name: 'Agile product management' },
          { id: 333, name: 'Certified Scrum Product Owner' },
          { id: 334, name: 'Product Ownership' },
          { id: 335, name: 'Software Development Life Cycle' },
          { id: 336, name: 'Requirements & Specifications Analysis' },
        ]
      },
      {
        id: 34,
        name: 'Business Analyst',
        roleId: 3,
        skills: [
          { id: 341, name: 'Jira' },
          { id: 342, name: 'Agile Product Management' },
          { id: 343, name: 'Requirements & Specification Analysis' },
          { id: 344, name: 'Business Analysis' },
          { id: 345, name: 'Requirements Analysis' },
          { id: 346, name: 'Product Management' },
          { id: 347, name: 'GAP Analysis' },
          { id: 348, name: 'Business Requirements' },
        ]
      }
    ]
  },
  {
    id: 4,
    name: 'Design and content',
    positions: [
      {
        id: 41,
        name: 'Technical writers',
        roleId: 4,
        skills: [
          {id: 411, name: 'Documentation'},
          {id: 412, name: 'Tutorials scripting'},
          {id: 413, name: 'User stories'},
          {id: 414, name: 'User interface'},
          {id: 415, name: 'Developer guides'},
          {id: 416, name: 'User assistance infrastructure'},
          {id: 417, name: 'Information architecture'},
          {id: 418, name: 'Infographics and screenshot markups'},
        ]
      },
      {
        id: 42,
        name: 'Designers',
        roleId: 4,
        skills: [
          {id: 421, name: 'Product designers'},
          {id: 422, name: 'Web designers'},
          {id: 423, name: 'Mobile designers'},
          {id: 424, name: 'UX/UI designers'},
          {id: 425, name: 'Adobe Photoshop'},
          {id: 426, name: 'Web design'},
          {id: 427, name: 'User Interface (UI)'},
          {id: 428, name: 'User Experience (UX)'},
          {id: 429, name: 'WordPress'},
          {id: 4210, name: 'Responsive Web Design'},
          {id: 4211, name: 'iOS, Android'},
          {id: 4212, name: 'Sketch'},
          {id: 4213, name: 'Wireframing'},
        ]
      }
    ]
  },
];

export namespace CategoriesData {
  export function getFirst100Roles(): Role[] {
    return first100Roles;
  }
  
  export function getHomeRoles(): Role[] {
    return homeRoles;
  }
  
  export function getCompanyRoles(): Role[] {
    return homeRoles;
  }
}