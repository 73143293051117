import './range-picker.sass';

import * as noUiSlider from '../../node_modules/nouislider/src/nouislider';

export namespace RangePicker {
  export function setup(element: HTMLElement): void {
    element.classList.add('range-picker');
    noUiSlider.create(element, {
      start: [8, 17],
      connect: true,
      step: 1,
      tooltips: {
        to: value => noPrecision(value) + ':00'
      },
      range: {
        min: 0,
        max: 24
      },
      margin: 1
    });
  }

  export function getValue(element: HTMLElement): number[] {
    return (element as any).noUiSlider.get(true).map(v => noPrecision(v));
  }

  export function setValue(element: HTMLElement, val: number[]): void {
    (element as any).noUiSlider.set(val);
  }

  function noPrecision(val: number | string) {
    if (typeof val === 'string') {
      val = parseInt(val);
    }
    return val.toFixed(0);
  }
}