import { BackendError, FieldError } from './common-repository';
import { Components } from './components';
import { DOM } from './dom';
import { Texts } from './texts';

export namespace Forms {
  export function read<T>(formId: string, data: T = {} as T): T {
    const form: HTMLFormElement = DOM.byId(formId);
    new FormData(form).forEach((value, name) => {
      data[name] = value;
    });
    return data as T;
  }

  export function processFile(file: File, onReady: (name?: string, data?: string) => void): void {
    if (file && file.size) {
      const reader = new FileReader();
      reader.onload = e => {
        onReady(file.name, e.target ? e.target.result as string : undefined);
      };
      reader.readAsDataURL(file);
    } else {
      onReady(undefined, undefined);
    }
  }

  export function convertCheckboxVal(val: any): boolean {
    return val === 'on';
  }

  export function setError(formId: string, errorMsg: string): void {
    clearErrors(formId);
    setErrorWithoutClear(formId, errorMsg);
  }

  export function handle(formId: string, errors: FieldError[] | FieldError): void {
    clearErrors(formId);
    let generalErrors: string[] = [];
    const handleError = (err: FieldError): void => {
      const msg = err.msgOverride ? err.msgOverride : err.message;
      if (!err.field || !setFieldError(err.field, msg)) {
        generalErrors.push(msg);
      }
    };
    if (Array.isArray(errors)) {
      for (const err of errors) {
        handleError(err);
      }
    } else {
      handleError(errors);
    }
    if (generalErrors.length) {
      setErrorWithoutClear(formId, generalErrors.join());
    }
  }

  export function handleBackendError(formId: string, err: BackendError, genericError: string = Texts.ERROR_GENERAL): void {
    if (!err || !err.message) {
      Forms.setError(formId, genericError);
    } else if (!err.errors) {
      Forms.setError(formId, err.message);
    } else {
      Forms.handle(formId, err.errors);
    }
  }

  export function replaceContent(formId: string, content: string, stateClassReplacement?: string): void {
    const form = DOM.byId(formId);
    if (form) {
      form.innerHTML = content;
      if (stateClassReplacement) {
        form.className = stateClassReplacement;
      }
    }
  }

  export function updateRequiredFields(formId: string, requiredFieldNames: string[]): void {
    const form: HTMLFormElement = DOM.byId(formId);
    for (let i = 0; i < form.elements.length; ++i) {
      const elem: Element = form.elements.item(i) as Element;
      if (elem.id) {
        Components.updateRequired(elem.id, requiredFieldNames.includes(elem.id));
      }
    }
  }

  function setErrorWithoutClear(formId: string, errorMsg: string): void {
    const formError = DOM.byId(formId + '-error');
    if (formError) {
      formError.innerText = errorMsg;
    }
  }

  export function clearErrors(formId: string): void {
    const form: HTMLFormElement = DOM.byId(formId);
    for (let i = 0; i < form.elements.length; ++i) {
      const elem: Element = form.elements.item(i) as Element;
      const elemError = DOM.byId(elem.id + '-error');
      if (elemError) {
        elemError.innerText = '';
      }
    }
    const formError = DOM.byId(formId + '-error');
    if (formError) {
      formError.innerText = '';
    }
  }

  function setFieldError(field: string, errorMsg: string): boolean {
    const elem = DOM.byId(field + '-error');
    if (elem) {
      elem.innerText = errorMsg;
      return true;
    }
    return false;
  }

  export function handleMaxCharCounter(name: string, maxLength: number, errorMsg?: string): void {
    const inputField = DOM.byId(name);
    const counterField = DOM.byId(`${name}-char-counter`);
    const errorLabel = DOM.byId(`${name}-error`);
    if (inputField && counterField && errorLabel) {
      inputField.addEventListener('input', (evt) => {
        let { target: { value } } = evt as any;
        if (value.length > maxLength) {
          value = value.slice(0, maxLength);
          (evt.target as any).value = value;
          errorLabel.innerHTML = errorMsg || Texts.MAX_CHAR_COMMON_ERROR(maxLength);
        } else {
          errorLabel.innerHTML = '';
        }
        counterField.innerHTML = `${value.length}/${maxLength}`;
      });
    }
  }
}